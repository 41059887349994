import {createSlice} from '@reduxjs/toolkit'
import api from '../../api'
import {toastError, toastSuccess} from '../../toast'


export const salesReportSlice = createSlice({
  name: 'salesReport',
  initialState: {
    data: {},
    loader: false,
  },
  reducers: {
    // PURE REDUCER
    setData: (state, action) => {
      state.data = action.payload
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload))
        let views = action.payload.views
        if (action.payload.views !== 'is_superuser') {
          views = JSON.stringify(action.payload.views)
        }
        localStorage.setItem('views', views)
      } else {
        localStorage.removeItem('views')
        localStorage.removeItem('user')
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setLoaderMe: (state, action) => {
      state.loaderMe = action.payload
    },
    setIsLogged: (state, action) => {
      state.isLogged = action.payload
    },
  },
})


const salesReportAction = salesReportSlice.actions
// EXTRA REDUCER

const verifySession = (redirectFunc = null) => (dispatch, store) => {
  dispatch(salesReportAction.setLoaderMe(true))
  const token = api.getToken()
  if (token && token.length > 4) {
    api.get('user/me')
      .then((response) => {
        dispatch(salesReportAction.setData(response.data))
        // redirectFunc && redirectFunc("/")
      })
      .catch((reason) => {
        api.removeToken()
        toastError(reason)
        redirectFunc && redirectFunc("/login")
      })
      .finally(() => {
        dispatch(salesReportAction.setLoaderMe(false))
      })
  } else {
    redirectFunc && redirectFunc("/login")
  }
  dispatch(salesReportAction.setLoaderMe(false))
}

const listSalesReport = (params = {id_persona: 1}, redirectFunc = null) => (dispatch, store) => {
  dispatch(salesReportAction.setLoader(true))
  api.get('report/sales_report', params)
    .then((response) => {
      dispatch(salesReportAction.setData(response.data))
      // redirectFunc && redirectFunc("/")
    })
    .catch((reason) => {
      api.removeToken()
      toastError(reason)
      redirectFunc && redirectFunc("/login")
    })
    .finally(() => {
      dispatch(salesReportAction.setLoader(false))
    })
}


// Action creators are generated for each case reducer function
export const actions = {
  verifySession,
  listSalesReport,
  ...salesReportSlice.actions,
}


export default salesReportSlice.reducer
