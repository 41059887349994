import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import {
  DateComponent,
  SelectComponent,
  InputComponent,
  CheckboxComponent,
  ImagenFileComponent,
  InputColor,
} from "../Inputs";
import {Form, FormikProvider, useFormik} from 'formik';
import {SecondaryButton, PrimaryButton} from "../Inputs/ButtonsComponents";
import {LoaderEllipsisComponent} from "../Loader/LoaderComponent";
import {useLocation} from 'react-router-dom';
import {connect} from "react-redux";


function FormComponentWithState({
                                  fields = {},
                                  data = {},
                                  fielArray,
                                  actionSubmit,
                                  isUpdate,
                                  isView,
                                  pathBack,
                                  ...props
                                }) {
  const [didMount, setMount] = useState(false);
  const [hasPermission, setPermission] = useState(false);
  const location = useLocation()
  const {user, textPrimaryButton, customGoBack} = props;
  const nav = useNavigate();
  let initialValues = {};
  let setValidationSchema = {};

  useEffect(() => {
    validatePermission()
    setMount(true)
  }, [])

  useEffect(() => {
    validatePermission()
  }, [didMount, user, location])

  const validatePermission = () => {
    const views = localStorage.getItem('views')
    if (views) {
      if (location.pathname === '/perfil') {
        setPermission(true)
      } else if (views === 'is_superuser') {
        setPermission(true)
      } else {
        const jsonViews = JSON.parse(views)
        if (jsonViews?.length) {
          jsonViews.forEach((view) => {
            if (view.view_f === location.pathname && view.create) {
              setPermission(true)
            } else if (view.view_f + '/crear' === location.pathname && view.create) {
              setPermission(true)
            } else if (location.pathname.includes(view.view_f) && location.pathname.includes('editar') && view.edit) {
              setPermission(true)
            } else if (location.pathname.includes(view.view_f) && location.pathname.includes('detalle') && view.read) {
              setPermission(true)
            } else if (location.pathname.includes(view.view_f) && location.pathname.includes('lista') && view.read) {
              setPermission(true)
            } else if (view.view_f === '/cajas') {
            setPermission(true)
            }
          })
        }
      }
    }
  }

  Object.keys(data).map(ele => {
    if (data[ele]) initialValues[ele] = data[ele];
  })

  Object.keys(fields).map(ele => {
    if (fields[ele].validation) setValidationSchema[ele] = fields[ele].validation;
  })

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values) => {
      actionSubmit(values)
    },
    validationSchema: Yup.object(setValidationSchema),
  });

  const goBack = () => customGoBack ? customGoBack() : nav(pathBack);

  return (
    <div className={`w-full h-full ${!hasPermission && 'hidden'} ${props.containerForm}`}>
      <LoaderEllipsisComponent loader={props.loader}/>
      <FormikProvider
        value={formik}
      >
        <Form className="h-full w-full mt-3">
          <div
            className={`grid gap-6 grid-flow-row grid-rows-2 grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 ${props.divFormGroup}`}>
            {Object.keys(fields).map((element, index) => {
              const TypeInput = fields[element].type ?? 'text';
              const inputProps = {
                key: index,
                name: element,
                disabled: isView,
                className: "",
                label: fields[element].label,
                type: TypeInput,
                ...fields[element]
              }
              return (
                ['imagen'].includes(TypeInput) ?
                  <ImagenFileComponent {...inputProps} />
                  :
                  ['select'].includes(TypeInput) ?
                    <SelectComponent {...inputProps} form={formik.values}/>
                    :
                    ['checkbox'].includes(TypeInput) ?
                      <CheckboxComponent {...inputProps} />
                      :
                      ['color'].includes(TypeInput) ?
                        <InputColor
                          {...inputProps}
                        />
                        :
                        ['array'].includes(TypeInput) ?
                          ''
                          :
                          ['date'].includes(TypeInput) ?
                            <DateComponent
                              {...inputProps}
                            />
                            :
                            <InputComponent
                              {...inputProps}
                            />
              )
            })}
          </div>
          {/* FIELD ARRAY SECTION */}
          {
            fielArray &&
            <div>
              {fielArray}
            </div>
          }

          <div className="flex md:flex-row lg:flex-row flex-col justify-between gap-3 mt-6">
            {pathBack ?
              <SecondaryButton
                type="button"
                text={'Regresar'}
                onClick={goBack}
              >
              </SecondaryButton> : <div></div>}
            {!isView &&
              <PrimaryButton
                text={isUpdate ? 'Actualizar' : textPrimaryButton ?? 'Crear'}
                disabled={!formik.isValid}
              >
              </PrimaryButton>
            }
          </div>
        </Form>
      </FormikProvider>
    </div>);

}

const states = (state) => {
  return {
    user: state.user.data,
  };
};

const FormComponent = connect(states, undefined)(FormComponentWithState);

export {FormComponent};