import ContainerComponent from "../../components/elements/ContainerComponent";
import {FormComponent} from "../../components/elements/Forms/FormComponent";
import {required} from "../../components/elements/Forms/validations";
import {Card, Typography} from "@material-tailwind/react";
import {LoaderEllipsisComponent} from "../../components/elements/Loader/LoaderComponent";


export const ProfileComponent = (props) => {

  let initialValues = props.data;

  const fields = {
    first_name: {label: 'Primer nombre', validation: required()},
    second_name: {label: 'Segundo Nombre',},
    third_name: {label: 'Tercer nombre',},
    last_name: {label: 'Primer apellido', validation: required()},
    second_last_name: {label: 'Segundo apellido'},
    phone_number: {label: 'Número de Teléfono',},
    address: {label: 'Dirección Domicilio',},
    email: {label: 'Correo'},
  }

  const handleSubmit = (values) => {
    const body = {
      person: values,
      idUser: props.data.id
    }
    props.updateProfile(body)
  }


  return (
    <ContainerComponent title={"Editar Perfil"}>
      <LoaderEllipsisComponent loader={props.loader || props.loaderMe}/>
      <div className="flex flex-col lg:flex-row md:flex-col gap-5">
        <div className="lg:w-4/12 xl:w-4/12 md:w-4/12 w-full">
          <Card className="shadow-none border-0">
              {/*<img src="/assets/perfil.png" alt="icon-profile" className="opacity-90"/>*/}
            <div className="py-2 text-left px-8">
              <Typography variant="h6" color="black">
                Rol:
                <span className="text-black/50">
                  {initialValues?.role_name ?? initialValues?.views == 'is_superuser'? 'Administrador': '' }
                </span>
              </Typography>
              <Typography variant="h6" color="black">
                Usuario:
                <span className="text-black/50">{initialValues?.username}</span>
              </Typography>
            </div>
          </Card>

        </div>
        <div className="lg:w-8/12 xl:w-8/12 md:w-8/12 w-full">
          <Card className="p-6 border w-full h-full">
            <FormComponent
              isUpdate={true}
              isView={false}
              fields={fields}
              data={initialValues?.person ?? {}}
              actionSubmit={handleSubmit}
              pathBack='/'
            />
          </Card>
        </div>
      </div>

    </ContainerComponent>
  )
}