import React, {useEffect, useState} from 'react'
import {TableWithStripedRows} from "../../../components/elements/Tables/TableComponent";
import {SALES_STATUS, setMoney} from "../../../utils/shared";
import ContainerComponent from "../../../components/elements/ContainerComponent";

function ContainerReport(props) {
  const {listSalesReport, data, loader} = props;
  const [didMount, setMount] = useState(false);
  const [params, setParams] = useState({});
  useEffect(() => {
    setMount(true)
  }, []);

  useEffect(() => {
    if (didMount) {
      listSalesReport(params)
    }
  }, [didMount]);

  const columnsName = [
    {selector: row => row.id, name: 'ID'},
    {selector: row => row.branch_office?.name, name: 'Sucursal'},
    {selector: row => row.cashier_person?.person?.first_name, name: 'Cajero/usuario'},
    {selector: row => row.creation_date, name: 'Fecha y Hora', variant: 'datetime'},
    {selector: row => SALES_STATUS.find((status) => status.value === row.status)?.label, name: 'Estado'},
    {selector: row => row.nit, name: 'NIT'},
    {selector: row => setMoney(parseFloat(row.total)), name: 'Total'},
  ]

  const Filters = [
    {
      placeholder: 'Fecha Inicio',
      name_filter: 'date_start',
      initialDate: new Date(),
      maxdate: undefined
    },
    {
      placeholder: 'Fecha Fin',
      name_filter: 'date_end',
      initialDate: new Date(),
      mindate: undefined
    },
    {
      placeholder: 'Sucursal',
      name_filter: 'branch_office_id',
      urlOptionList: 'branch-offices/select'
    },
    {
      placeholder: 'Usuario',
      name_filter: 'user_id',
      urlOptionList: 'user/select_option'
    },
    {
      placeholder: 'Estado',
      name_filter: 'estado',
      urlOptionList: '',
      options: SALES_STATUS
    },
  ]

  const changeFilterById = (filterValue) => {
    if (params && typeof (params) === 'object') {
      // Si ya existe un filtro que lo mantenga, y que sobrescriba su valor
      setParams({...params, ...filterValue})
    } else {
      // de lo contrario que agregue ese filtro
      setParams(filterValue);
    }
    listSalesReport(params)

  }

  return (
    <ContainerComponent
      title={'Reporte de Ventas'}
    >
      <TableWithStripedRows
        filters={Filters}
        loader={loader}
        data={data}
        changeFilter={changeFilterById}
        showSearch={false}
        dontShowAction={true}
        columns={columnsName}
      />

    </ContainerComponent>
  )
}

export default ContainerReport;