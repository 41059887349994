import {createSlice} from '@reduxjs/toolkit'
import api from '../../api'
import {toastError} from '../../toast'


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    data: {},
    loader: false,
  },
  reducers: {
    // PURE REDUCER
    setData: (state, action) => {
      state.data = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
  },
})


const dashboardAction = dashboardSlice.actions

const getDataDashboard = (data ={}) => (dispatch) => {
  dispatch(dashboardAction.setLoader(true))
  api.get("/dashboard-data", data)
    .then(({data}) => {
      // toastSuccess('response')
      dispatch(dashboardAction.setData(data))
    })
    .catch((reason) => {
      toastError(reason)
    })
    .finally(() => {
      dispatch(dashboardAction.setLoader(false))
    })
}

// Action creators are generated for each case reducer function
export const actions = {
  getDataDashboard,
}


export default dashboardSlice.reducer
