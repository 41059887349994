import * as moment from "moment";

moment.locale('es');

export function setMoney(value) {
    if (!value) return 'Q 0.00'
    return value.toLocaleString('es-GT', {
        style: 'currency',
        currency: 'GTQ'
    })
}

export function setDatetime(date) {
    return moment.utc(date).format('DD/MM/YYYY h:mm:ss');
}

export function setDatetimeForDjango(date) {
    const today = moment()
    let dateMoment = moment(date);
    dateMoment.add(today.hours(), 'hours');
    dateMoment.add(today.minutes(), 'minutes');
    dateMoment.add(today.seconds(), 'seconds');
    return moment(dateMoment).format('YYYY-MM-DD h:mm:ss');
}

export function setDateString(date) {
    return moment(date).format('DD/MM/YYYY');
}

export function setDateInput(date) {
    return moment(date).format('YYYY-MM-DD');
}

export const WarehouseStatus = [
    {value: 1, label: 'Activo'},
    {value: 5, label: 'Inactivo'},
    {value: 10, label: 'En mantenimiento'},
]


// CONSTANTES
export const PRICE_TYPE = [
    {value: '1', label: "Precio Compra", status: 1},
    {value: '2', label: "Precio Venta Sugerido", status: 1, disabled: true},
    {value: '3', label: "Precio Venta Especial", status: 1},
    {value: '4', label: "Precio Venta Mayorista", status: 1},
]

export const PRICE_SALE = '2'
export const PRICE_PURCHASE = '1'

export const PRODUCT_WEIGHT = [
    {value: '1', label: "Quintal"},
    {value: '2', label: "Arroba"},
    {value: '3', label: "Libra"},
    {value: '4', label: "Onza"},
    {value: '5', label: "Gramos"},
    {value: '6', label: "Kilogramo"},
    {value: '7', label: "Litro"},
    {value: '8', label: "Mililitro"},
    {value: '9', label: "Centimetro"},
    {value: '10', label: "Pulgadas"},
    {value: '11', label: "Octavo"},
]

export const INITIAL_VALUE_PRICE = [{
    price_type: '2',
    amount: null,
}]

export const INITIAL_VALUE_PRESENTATIONS = [{
    name: '',
    description: '',
    unit: '',
    conversion: 1,
    prices: INITIAL_VALUE_PRICE
}]

export const INITIAL_VALUES_FORM = {
    // name: null,
    // description: undefined,
    // category: undefined,
    hasPresentation: true,
    // prices: INITIAL_VALUE_PRICE,
    presentations: INITIAL_VALUE_PRESENTATIONS
};

export const WAREHOUSE_STATUS = [
    {value: 1, label: "Activo"},
    {value: 5, label: "Inactivo"},
    {value: 10, label: "En mantenimiento"},
]

export const PURCHASE_STATUS = [
    {value: 1, label: "Completada"},
    // {value: 5, label: "En camino"},
    {value: 10, label: "Finalizado"},
    {value: 15, label: "Anulado"},
]

export const SALES_STATUS = [
    {value: 1, label: "Facturado"},
    {value: 5, label: "No Facturado"},
    {value: 10, label: "Anulado"},
]


export const CODE_ERROR_CATALOGS = [
    {
        CODE: 'WS1',
        NAME: 'WITHOUT_SUPPLIER',
        MESSAGE: 'Crea un proovedor para poder continuar con la creacion de una compra'
    }
]


export const ROWS_PER_PAGE = 10