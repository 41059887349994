import {connect} from 'react-redux';
import {actions} from '../../../js/redux/salesReport';
import ContainerSalesReport from './ContainerReport';

const salesReportState = (state) => {
  return {
    ...state.salesReport,
  };
};


const SalesReportConn =  connect(salesReportState, actions)(ContainerSalesReport);
export {
  SalesReportConn
}